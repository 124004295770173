var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.menu),function(mn,mnIndex){return _c('router-link',{key:mnIndex,attrs:{"to":mn.target},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[{ 'menu-item-open': _vm.hasActiveChildren(mn.target+'/') }, isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":function($event){(mn.children && mn.children.length > 0) ? false : navigate}}},[_c('i',{class:mn.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(mn.label))]),(mn.children && mn.children.length > 0)?_c('i',{staticClass:"menu-arrow"}):_vm._e()]),(mn.children && mn.children.length > 0)?_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((mn.children),function(mnc,mncIndex){return _c('router-link',{key:mncIndex,attrs:{"to":mn.target+'/'+mnc.target},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":(mnc.children && mnc.children.length > 0) ? '#' : href},on:{"click":function($event){(mnc.children && mnc.children.length > 0) ? false : navigate}}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(mnc.label))]),(mnc.children && mnc.children.length > 0)?_c('i',{staticClass:"menu-arrow"}):_vm._e()]),(mnc.children && mnc.children.length > 0)?_c('div',{staticClass:"menu-submenu"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((mnc.children),function(mncc,mnccIndex){return _c('router-link',{key:mnccIndex,attrs:{"to":mn.target+'/'+mnc.target+'/'+mncc.target},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":function($event){(mncc.children && mncc.children.length > 0) ? false : navigate}}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(mncc.label))])])])]}}],null,true)})}),1)]):_vm._e()])]}}],null,true)})}),1)]):_vm._e()])]}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }